import {INPUT_TYPE_SEARCH, INPUT_TYPE_TEXT,} from '@/assets/js/src/modules/sidebar/_pinia/_constants'
import {computed, getCurrentInstance, nextTick, ref, watch,} from 'vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useSidebarStore,} from '@/assets/js/src/modules/sidebar/_pinia/sidebar'
import {LOADING,} from '@/assets/js/src/util/composables/usePrefetchData'

export function useUpdateContent ({provider, types, limit,}) {
    const _limit = ref(limit)
    const myContent = ref([])
    const globalContent = ref([])

    let activePinia = getActivePinia()
    let sidebarStore = useSidebarStore(activePinia)
    let {input,status: loadingStatus,} = storeToRefs(sidebarStore)
    let {proxy,} = getCurrentInstance()

    const updateContent = function () {
        if(Array.isArray(provider)) {
            let _my = {}
            let _global = {}
            provider.forEach((name) => {
                let {my, global,} = sidebarStore.getProvidersContent({
                    type: input.value.type,
                    name,
                    inputFilter: input.value.filter,
                })
                _my[name] = my || []
                _global[name] = global || []
            })

            myContent.value = _my
            globalContent.value = _global
            _limit.value = limit
        } else {
            let {my, global,} = sidebarStore.getProvidersContent({
                type: input.value.type,
                name: provider,
                inputFilter: input.value.filter,
            })

            myContent.value = my || []
            globalContent.value = global || []
            _limit.value = limit
        }

        if (typeof proxy.postUpdate === 'function') {
            proxy.postUpdate()
        }
    }

    const filter = computed(() => input.value.filter)
    watch(filter, (newValue, oldValue) => {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            updateContent()
        }
    })

    types.forEach((type) => {
        if ([ INPUT_TYPE_TEXT, INPUT_TYPE_SEARCH, ].includes(type)) {
            let watcher = function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    nextTick().then(() => {
                        updateContent()
                    })
                }
            }
            if(Array.isArray(provider)) {
                provider.forEach((name) => {
                    watch(()=>sidebarStore[`${type}Content`][name], watcher,{immediate: true,})
                })
            } else {
                watch(()=>sidebarStore[`${type}Content`][provider], watcher,{immediate: true,})
            }
        }
    })

    const loadingStatusLoading = computed(()=>loadingStatus.value === LOADING)

    return {
        limit: _limit,
        myContent,
        globalContent,
        loadingStatus,
        loadingStatusLoading,
        filter,
    }
}
