<template>
    <v-tooltip
        location="bottom"
        :disabled="tooltipDisabled"
        attach=".v-application"
    >
        <template #activator="{ props }">
            <v-btn
                :class="{'hidden-sm-and-down':tooltipDisabled,'like-btn':!tooltipDisabled}"
                :aria-label="messagesLoaded ? t('async.sidebar.like.icon_title') : ''"
                v-bind="{
                    ...specialAttrs,
                    ...props,
                }"
                :icon="fab"
                variant="text"
                :color="color"
                @click="handleLike"
            >
                <v-icon v-if="!status && !marked">
                    bs:$vuetify.icons.mdiHeartOutline
                </v-icon>
                <v-icon v-else>
                    bs:$vuetify.icons.mdiHeart
                </v-icon>
            </v-btn>
        </template>
        <span v-if="!tooltipDisabled && messagesLoaded && mostLiked.count >= 1">{{ tc('async.sidebar.like.count', mostLiked.count) }}<!--
            --><span v-if="!tooltipDisabled && messagesLoaded && filterArray.length !== 1">&nbsp;&ndash;&nbsp;{{ textRef }}</span><!--
        --></span>
        <span v-if="!tooltipDisabled && messagesLoaded && mostLiked.count < 1">{{ tc('async.sidebar.like.do_like', filterArray.length) }}</span>
    </v-tooltip>
</template>

<script>
import {INPUT_TYPE_TEXT,} from '@/assets/js/src/modules/sidebar/_pinia/_constants'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {COOKIE_LIKES,} from '@/assets/js/src/modules/like/_pinia/_constants'
import {useLikeStore,} from '@/assets/js/src/modules/like/_pinia/like'
import {getActivePinia, mapActions, mapState,} from 'pinia'
import {useSnackbarStore,} from '@/assets/js/src/modules/snackbar/_pinia/snackbar'
import {useUpdateContent,} from '@/assets/js/src/modules/sidebar/_composables/useUpdateContent'
import {useBibleRefs,} from '@/assets/js/src/util/composables/useBibleRefs'
import {useSidebarStore,} from '@/assets/js/src/modules/sidebar/_pinia/sidebar'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useTextStore,} from '@/assets/js/src/modules/text/_pinia/text'
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

export default {
    name: 'LikeButton',
    props: {
        fab: {
            type: Boolean,
            default: true,
        },
        tooltipDisabled: {
            type: Boolean,
            default: false,
        },
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
        canonical: {
            type: Number,
            default: 0,
        },
        canonicals: {
            type: Array,
            default: ()=>[],
        },
        color: {
            type: String,
            default: 'primary',
        },
    },
    setup () {
        let {createLikes, deleteLikes,} = useLikeStore(getActivePinia())

        return {
            createLikes,
            deleteLikes,
            ...useUpdateContent({
                provider: 'like',
                types: [ INPUT_TYPE_TEXT, ],
                limit: 2,
            }),
            ...useBibleRefs(),
            ...useTranslation(),
        }
    },
    data () {
        return {
            marked: false,
            tmpLikes: [],
        }
    },
    computed: {
        ...mapState(useSidebarStore,[
            'input',
        ]),
        ...mapState(useUserStore,[
            'loggedIn',
        ]),
        ...mapState(useTextStore,[
            'allVerses',
        ]),
        filterArray: function () {
            if(Array.isArray(this.filter)) {
                return this.filter
            }
                
            return []
        },
        mostLiked: function () {
            return this.globalContent.reduce((mostLiked, likeCount) => likeCount.count > mostLiked.count ? likeCount : mostLiked, {count: 0,})
        },
        status: function () {
            if (this.canonicals.length) {
                return this.marked || this.canonicals.every(canonical => this.tmpLikes.includes(canonical))
            } else if (this.filterArray.length === 0) {
                try {
                    return this.allVerses[0][1] === Object.keys(this.idsMap).length
                } catch (e) {
                    return false
                }
            }

            return this.filterArray.every((canonical) => {
                return Object.keys(this.idsMap).includes(canonical.toString()) || this.tmpLikes.includes(canonical)
            })

        },
        idsMap: function () {
            return this.myContent.reduce((sorted, likeEntity) => {
                sorted[likeEntity.canonical] = likeEntity.id

                return sorted
            }, {})
        },
        ids: function () {
            return this.filterArray.reduce((ids, canonical) => {
                let id = this.idsMap[canonical]
                if (typeof id !== 'undefined') {
                    ids.push(id)
                }

                return ids
            }, [])
        },
        diff: function () {
            return this.filterArray.filter((canonical) => !Object.keys(this.idsMap).includes(canonical.toString()))
        },
        specialAttrs: function () {
            return (this.tooltipDisabled && this.messagesLoaded) ? {'title': this.tc('async.sidebar.like.do_like', this.filterArray.length === 0 ? 2 : this.filterArray.length),} : null
        },
        textRef: function () {
            let canonicals = [ this.mostLiked.canonical, ]
            if(this.canonicals.length) {
                canonicals = this.canonicals
            }

            return this.getRefByCanonicals({canonicals, type: this.REF_SHORT,})
        },
    },
    watch: {
        canonical (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.marked = false
            }
        },
        filterArray (newVal, oldVal) {
            if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                this.marked = false
            }
        },
    },
    created () {
        this.idsMapLocal = {}
    },
    mounted () {
        this.tmpLikes = JSON.parse(cookieManager.get(COOKIE_LIKES) || '[]')
    },
    methods: {
        ...mapActions(useSnackbarStore,[ 'showMessage', ]),
        handleLike: async function () {
            if (this.filterArray.length === 0 && this.canonical === 0 && this.canonicals.length === 0) {
                this.showMessage({
                    message: this.t('async.sidebar.like.no_chapter_notice'),
                    type: 'info',
                })
            } else {
                if (!this.status) {
                    let canonicals = this.canonical ? [ this.canonical, ] : this.diff
                    let updateSidebar = true
                    if(this.canonicals.length) {
                        canonicals = this.canonicals
                        updateSidebar = false
                    }
                    this.createLikes({
                        canonicals: canonicals,
                        updateSidebar,
                    }).then((ids) => {
                        if(this.canonicals.length) {
                            this.idsMapLocal[this.canonicals.join('_')] = ids
                        }
                        this.marked = !!this.canonical || !!this.canonicals.length

                        if(!this.loggedIn) {
                            this.tmpLikes = JSON.parse(cookieManager.get(COOKIE_LIKES) || '[]')
                            this.marked = this.diff.every((canonical)=>this.tmpLikes.includes(canonical))
                        }
                    })
                    this.$bsa && this.$bsa.event({
                        eventCategory: 'button actions',
                        eventAction: 'Vers gefällt mir',
                        eventName: canonicals[0],
                    })
                } else if(this.loggedIn) {
                    let ids = this.ids
                    let updateSidebar = true
                    let canonicals = this.filterArray
                    if(this.canonicals.length) {
                        ids = this.idsMapLocal[this.canonicals.join('_')] || []
                        updateSidebar = false
                        canonicals = this.canonicals
                    }
                    this.deleteLikes({
                        ids,
                        updateSidebar,
                        canonicals,
                    }).then(() => {
                        if(this.canonicals.length) {
                            delete this.idsMapLocal[this.canonicals.join('_')]
                        }
                        this.tmpLikes = JSON.parse(cookieManager.get(COOKIE_LIKES) || '[]')
                        this.marked = false
                    })
                    this.$bsa && this.$bsa.event({
                        eventCategory: 'button actions',
                        eventAction: 'Vers gefällt mir nicht mehr',
                        eventName: canonicals[0],
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss">
    .bs-app .like-btn {
        margin-right: 8px !important;
    }
</style>
